import { React } from '@/vendor'
import { Button } from '@/components'

export default {
	title: '2023 Los Idiots',
	date: '2024-10-24',
	image: require('./2023-los-idiots@2x.png'),
	body: (
		<>
			<p>
				A coferment of 54% Riesling and 46% Blaufränkisch. It's 2023 for Lightwell Survey and Los Idiots has finally arrived. I'm not saying the past efforts weren’t good, they just took a while to reveal themselves. Their beautiful quirkiness, a magical concoction of 50/50 white to red year in and year out has been a prickly pear that takes time to homogenize. This year, however, it bursts out of the glass fully formed. “I am weird!!  Hear me roar!” Both floral and herbal, this wine is brilliant in its youth. Reminds us of a ripe Sicilian Frappato, so pretty and juicy. Take this wine to ChillVille, Holler!!! 75 cases produced.
			</p>
			<p>
				<strong>FRUIT SOURCES</strong>
				<br />
				Shenandoah VIneyards Riesling and Blaufränkisch
			</p>
			<p>
				<strong>WINEMAKING</strong>
				<br />
				The chance to source these two grapes in 2023 from one of the oldest 
				vineyards in Virginia allowed us to resurrect “Los”, the lighter, more 
				aromatic of the two “Idiots” wines. The Blaufränkisch was picked, 
				destemmed and started the fermentation. Five days later the Riesling 
				was picked and added as whole clusters to the red grapes. Both grapes 
				fermented together on the skins to dryness and then were pressed to a 
				500L puncheon and a 228L barrel, both neutral French oak. After malo, 
				the wine aged in barrel for ten months before bottling unfiltered with 
				a single 25ppm SO2 addition on 7/23/24.
			</p>
			<p>
				<strong>CHEMISTRY</strong>
				<br />
				pH: 3.5
				<br />
				TA: 5.5 g/L
				<br />
				Free SO2 at bottling: ~12 ppm
				<br />
				Alcohol: 11.2% abv
				<br />
				RS: {'<'}0.5 g/L
				<br />
				Malic Acid: {'<'}0.1 g/L
				<br />
				Acetic acid at bottling: 0.5 g/L
			</p>
		</>
	),
	cta: <Button.Link to="/shop">Shop Wines</Button.Link>,
}
